import { CountriesCodes } from './types';

const INTERNATIONAL_NUMBER_SYMBOL = '+';
const TEMPLATE_NUMBER_SYMBOL = 'x';

export const getCountryCallingCode = ({
    countriesCallingCodes,
    countryCode,
}: {
    countriesCallingCodes: CountriesCodes;
    countryCode: string;
}): string => {
    const callingCode =
        (countriesCallingCodes || []).find(country => country?.abbreviation === countryCode)
            ?.countryCallingCode || '+1';
    return callingCode;
};

export const isInternationalNumber = (value: string): boolean =>
    value[0] === INTERNATIONAL_NUMBER_SYMBOL;

export const removeNumberFormatting = (value: string): string => {
    const isInternational = isInternationalNumber(value);
    const removeFrom = isInternational ? value.substring(1) : value;
    const strippedNumber = removeFrom.replace(/\D/g, '');
    const returnValue = isInternational ? value[0] + strippedNumber : strippedNumber;
    return returnValue;
};

export const handleTextDeletion = ({
    value,
    selectionStart,
    selectionEnd,
    notFormattedNumber,
}: {
    value: string;
    selectionStart: number;
    selectionEnd: number;
    notFormattedNumber: string;
}): string => {
    let newValue = '';
    if (selectionStart !== selectionEnd) {
        const textToDelete = value.substring(selectionStart, selectionEnd);
        const clearedTextToDelete = removeNumberFormatting(textToDelete);
        newValue = notFormattedNumber.replace(clearedTextToDelete, '');
    } else {
        const valueBefore = value.substring(0, selectionStart);
        const valueAfter = value.substring(selectionStart);
        let foundWhatToDelete = false;
        newValue =
            valueBefore
                .split('')
                .reverse()
                .filter(s => {
                    const del = foundWhatToDelete;
                    const isValidSymbol = !isNaN(parseInt(s, 10)) || s === '+';
                    if (!foundWhatToDelete && isValidSymbol) {
                        foundWhatToDelete = true;
                    }
                    return isValidSymbol && del;
                })
                .reverse()
                .join('') + `${valueAfter ? removeNumberFormatting(valueAfter) : ''}`;
    }
    return newValue;
};

// takes template for example (x-xxx) xxxxx
// and returns index of x's positioned at 'position'
export const getCursorPosition = (position: number, template: string): number => {
    let newPosition = position;
    const templateLength = template.length;
    let index = 0;
    if (position > templateLength) {
        newPosition = templateLength;
    } else {
        while (position !== 0 && index <= templateLength) {
            if (template[index] === TEMPLATE_NUMBER_SYMBOL) {
                newPosition = index + 1;
                position--;
            }
            index++;
        }
    }
    return newPosition;
};
