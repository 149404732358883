import {
    CountriesCodes,
    CountryChangedType,
    CountryChangedReturnType,
    PhoneChangedType,
    PhoneChangedReturnType,
    InitialValues,
    PhoneNumberState,
    AsYouTypeInstance,
    AsYouTypeClass,
    OnChangeCallback,
} from './types';
import { getCountryCallingCode, removeNumberFormatting, isInternationalNumber } from './helpers';

const onCountryChanged = ({
    AsYouTypeHelper,
    formattedNumber,
    countriesCallingCodes,
    countryCode,
    resetInternationalValue,
    onChange,
}: CountryChangedType): CountryChangedReturnType => {
    const countryCallingCode = getCountryCallingCode({ countriesCallingCodes, countryCode });
    let updatedChangeHandler;
    let formattedOutput = '';
    if (AsYouTypeHelper) {
        updatedChangeHandler = new AsYouTypeHelper(countryCode);
        updatedChangeHandler.reset();
        if (
            (isInternationalNumber(formattedNumber) && resetInternationalValue) ||
            !formattedNumber
        ) {
            formattedOutput = updatedChangeHandler.input(countryCallingCode);
        } else {
            formattedOutput = updatedChangeHandler.input(formattedNumber);
        }
    }
    const internationalNumber = updatedChangeHandler?.getNumber()?.number?.toString() || '';
    const isValidNumber = updatedChangeHandler?.isValid() || false;

    if (onChange) {
        onChange({ internationalNumber, isValidNumber, countryCallingCode });
    }

    return {
        formattedNumber: formattedOutput,
        isValidNumber: updatedChangeHandler?.isValid() || false,
        selectedCountry: countryCode,
        countryCallingCode,
        phoneChangeHandler: updatedChangeHandler,
        notFormattedNumber: removeNumberFormatting(formattedOutput),
        internationalNumber,
        template: updatedChangeHandler?.getTemplate() || '',
    };
};

const onNumberChanged = ({
    phoneChangeHandler,
    AsYouTypeHelper,
    newValue,
    countryCode,
    countriesCallingCodes,
    countryCallingCode,
    onChange,
}: PhoneChangedType): PhoneChangedReturnType => {
    phoneChangeHandler?.reset();
    const formattedNumber = phoneChangeHandler?.input(newValue) || '';
    const parsedCountry = phoneChangeHandler?.getCountry();
    if (!!parsedCountry && parsedCountry !== countryCode) {
        return onCountryChanged({
            AsYouTypeHelper,
            formattedNumber,
            countriesCallingCodes,
            countryCode: parsedCountry,
            onChange,
        });
    }
    const internationalNumber = phoneChangeHandler?.getNumber()?.number?.toString() || '';
    const isValidNumber = phoneChangeHandler?.isValid() || false;
    if (onChange) {
        onChange({ internationalNumber, isValidNumber, countryCallingCode });
    }

    return {
        isValidNumber,
        formattedNumber,
        notFormattedNumber: removeNumberFormatting(formattedNumber),
        internationalNumber,
        template: phoneChangeHandler?.getTemplate() || '',
    };
};

export const getInitialState = ({
    defaultCountry,
    countriesCallingCodes,
}: InitialValues): PhoneNumberState => {
    return {
        formattedNumber: '',
        notFormattedNumber: '',
        internationalNumber: '',
        selectedCountry: defaultCountry,
        isValidNumber: false,
        countriesCallingCodes,
        countryCallingCode: getCountryCallingCode({
            countriesCallingCodes,
            countryCode: defaultCountry,
        }),
        phoneChangeHandler: undefined,
        AsYouTypeHelper: undefined,
        template: '',
    };
};

export type PhoneNumberAction =
    | {
          type: 'UPDATE';
          countriesCallingCodes: CountriesCodes;
          formattedNumber: string;
          notFormattedNumber: string;
          selectedCountry: string;
          isValidNumber: boolean;
      }
    | {
          type: 'ADD_PHONE_NUMBER_HANDLER';
          phoneChangeHandler: AsYouTypeInstance;
          asYouTypeHelper: AsYouTypeClass;
      }
    | {
          type: 'COUNTRY_CHANGED';
          countryCode: string;
          resetInternationalValue?: boolean;
          onChange?: OnChangeCallback;
      }
    | {
          type: 'VALUE_CHANGED';
          newValue: string;
          onChange?: OnChangeCallback;
      };

export const phoneNumberReducer = (
    state: PhoneNumberState,
    action: PhoneNumberAction
): PhoneNumberState => {
    const {
        AsYouTypeHelper,
        formattedNumber,
        countriesCallingCodes,
        countryCallingCode,
        selectedCountry,
        phoneChangeHandler,
    } = state;
    switch (action.type) {
        case 'UPDATE':
            return {
                ...state,
                formattedNumber: action.formattedNumber,
                notFormattedNumber: action.notFormattedNumber,
                internationalNumber: action.notFormattedNumber,
                selectedCountry: action.selectedCountry,
                isValidNumber: action.isValidNumber,
                countryCallingCode: getCountryCallingCode({
                    countriesCallingCodes: action.countriesCallingCodes,
                    countryCode: action.selectedCountry,
                }),
                countriesCallingCodes: action.countriesCallingCodes,
            };
        case 'ADD_PHONE_NUMBER_HANDLER':
            return {
                ...state,
                phoneChangeHandler: action.phoneChangeHandler,
                AsYouTypeHelper: action.asYouTypeHelper,
            };
        case 'COUNTRY_CHANGED':
            return {
                ...state,
                ...onCountryChanged({
                    AsYouTypeHelper,
                    formattedNumber,
                    countriesCallingCodes,
                    countryCode: action.countryCode,
                    resetInternationalValue: action.resetInternationalValue,
                    onChange: action.onChange,
                }),
            };
        case 'VALUE_CHANGED':
            return {
                ...state,
                ...onNumberChanged({
                    AsYouTypeHelper,
                    phoneChangeHandler,
                    countriesCallingCodes,
                    countryCallingCode,
                    countryCode: selectedCountry,
                    newValue: action.newValue,
                    onChange: action.onChange,
                }),
            };
        default:
            return state;
    }
};
