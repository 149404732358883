import {
    FC,
    useState,
    ChangeEvent,
    KeyboardEvent,
    useEffect,
    ReactNode,
    useRef,
    useReducer,
} from 'react';
import classnames from 'classnames';
import { sessionStorage as dibsSessionStorage } from 'dibs-browser-storage';

import { Input } from 'dibs-elements/exports/Input';
import { Spinner } from 'dibs-elements/exports/Spinner';
import { BasicSelect } from 'dibs-elements/exports/BasicSelect';
import { Link } from 'dibs-elements/exports/Link';
import { RotatingArrow } from 'dibs-elements/exports/RotatingArrow';

import { phoneNumberReducer, getInitialState } from './phoneNumberReducer';
import {
    FocusEventHandler,
    CountriesCodes,
    ParsedNumber,
    Country,
    OnChangeCallback,
    OnChangeCallbackArgs,
} from './types';
import { handleTextDeletion, removeNumberFormatting, getCursorPosition } from './helpers';

import styles from './main.scss';

type Props = {
    autoFocus?: boolean;
    dataTn: string;
    errorDataTn?: string;
    name?: string;
    hasError?: boolean;
    errorMessage?: ReactNode | string;
    disabled?: boolean;
    size?: 'small' | 'medium' | 'large';
    onBlur?: FocusEventHandler;
    onFocus?: FocusEventHandler;
    onChange?: FocusEventHandler;
    placeholder?: string;
    type?: string;
    wrapperClass?: string;
    hasValidText?: boolean;
    isLoading?: boolean;
    countriesCodes?: CountriesCodes;
    parsedPhoneNumber?: ParsedNumber;
    label?: ReactNode;
    flagWrapperPosition?: string;
    errorMessageAlignment?: string;
    countryCode?: string;
};

const USER_GEO_INFO = 'userGeoInfo';
// Flag image will be shown in 21px width
// To increase pixel density fetching 2x width image
const FLAG_IMAGE_SIZE = '42';

type UserGeoInfo = {
    countryCode: string | null;
    timestamp: string | null;
    zipCode: string | null;
    incomeLevel: { incomeBracket: string | null; incomePercentile: string | null } | null;
    regionsByZipCode?: [{ displayName: string; urlLabel: string }];
};

export const PhoneNumber: FC<Props> = ({
    wrapperClass,
    name,
    onChange,
    onBlur,
    onFocus: onFocusCb,
    type,
    dataTn,
    size = 'large',
    placeholder,
    errorMessage,
    hasError,
    disabled,
    hasValidText,
    autoFocus,
    errorDataTn,
    countriesCodes,
    parsedPhoneNumber,
    isLoading,
    label,
    flagWrapperPosition = 'top',
    errorMessageAlignment,
    countryCode: countryCodeProp,
}) => {
    const countriesOptions = (countriesCodes || []).map((country: Country) => ({
        value: country?.abbreviation || '',
        label: country?.name || '',
    }));
    const {
        countryCode: parsedCountryCode,
        internationalNumber: parsedInternationalNumber,
        countryCallingCode: parsedCountryCallingCode,
        number,
        isValid,
    } = parsedPhoneNumber || {};
    const { countryCode: sessionCountryCode } =
        (dibsSessionStorage.getItem(USER_GEO_INFO) as UserGeoInfo) || {};

    const defaultCountry = countryCodeProp || sessionCountryCode || 'US';

    const [state, dispatch] = useReducer(
        phoneNumberReducer,
        getInitialState({
            defaultCountry,
            countriesCallingCodes: countriesCodes || [],
        })
    );
    const inputNodeRef = useRef<HTMLInputElement | null>();
    const textRef = useRef('');
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [deletedNumber, setDeletedNumber] = useState('');
    const [autoFocusFired, setAutoFocusFired] = useState(false);
    const [fieldIsBlurred, setFieldIsBlurred] = useState(false);
    const {
        selectedCountry,
        formattedNumber,
        notFormattedNumber,
        isValidNumber,
        phoneChangeHandler,
        template,
    } = state;
    // If there's a phoneNumber passed as a prop
    // It will be parsed on graphql to avoid loading external library just to show formatted phoneNumber
    useEffect(() => {
        dispatch({
            type: 'UPDATE',
            formattedNumber: parsedInternationalNumber || '',
            notFormattedNumber: number || '',
            isValidNumber: isValid || false,
            selectedCountry: parsedCountryCode || defaultCountry,
            countriesCallingCodes: countriesCodes || [],
        });
    }, [
        parsedInternationalNumber,
        number,
        isValid,
        parsedCountryCode,
        defaultCountry,
        countriesCodes,
        dispatch,
    ]);

    useEffect(() => {
        if (autoFocus && onFocusCb && !autoFocusFired && !fieldIsBlurred && number) {
            onFocusCb({
                isValid: isValid || false,
                value: number || '',
                countryCallingCode: parsedCountryCallingCode || '',
            });
            setAutoFocusFired(true);
        }
    }, [
        onFocusCb,
        autoFocus,
        autoFocusFired,
        number,
        parsedCountryCallingCode,
        isValid,
        fieldIsBlurred,
    ]);

    useEffect(() => {
        if (inputNodeRef.current && textRef.current) {
            const node = inputNodeRef.current;
            const cursor = getCursorPosition(
                removeNumberFormatting(textRef.current).length,
                template
            );
            node.selectionStart = cursor;
            node.selectionEnd = cursor;
        }
    }, [template]);

    const getOnChangeCallback = (): OnChangeCallback => {
        return onChange
            ? ({
                  internationalNumber: internationalNumberFromState,
                  isValidNumber: isValidFromState,
                  countryCallingCode: countryCallingCodeFromState,
              }: OnChangeCallbackArgs) =>
                  onChange({
                      name,
                      isValid: isValidFromState,
                      value: internationalNumberFromState,
                      countryCallingCode: countryCallingCodeFromState,
                  })
            : undefined;
    };

    const initPhoneNumberChangeHandler = async (): Promise<void> => {
        const { AsYouType } = await import(
            /* webpackChunkName: "PhoneNumberChangeHandler" */ 'libphonenumber-js/min/exports/AsYouType'
        );
        dispatch({
            type: 'ADD_PHONE_NUMBER_HANDLER',
            phoneChangeHandler: new AsYouType(selectedCountry),
            asYouTypeHelper: AsYouType,
        });
    };
    // There's two cases when we are updating country
    // First when we select country
    // Second when we start typing international country code
    // When country is selected input value is reset and added international country calling code
    const updateCountryCode = ({
        countryCode,
        resetInternationalValue = false,
    }: {
        countryCode: string;
        resetInternationalValue?: boolean;
    }): void => {
        dispatch({
            type: 'COUNTRY_CHANGED',
            countryCode,
            resetInternationalValue,
            onChange: getOnChangeCallback(),
        });
    };

    const phoneNumberChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const target = e.target;
        const { value, selectionStart } = target;
        textRef.current = e.target.value.substring(0, selectionStart || 0);
        let newValue = '';

        if (value) {
            newValue = deletedNumber ? deletedNumber : value;
        }
        dispatch({
            type: 'VALUE_CHANGED',
            newValue,
            onChange: getOnChangeCallback(),
        });
    };

    const phoneNumberBlur = (): void => {
        setAutoFocusFired(false);
        setFieldIsBlurred(true);
        if (onBlur) {
            onBlur({ name, value: state.internationalNumber, isValid: isValidNumber });
        }
    };

    const onCountryCodeChange = async (e: ChangeEvent<HTMLSelectElement>): Promise<void> => {
        if (!phoneChangeHandler) {
            await initPhoneNumberChangeHandler();
        }
        updateCountryCode({
            countryCode: e.target.value || 'US',
            resetInternationalValue: true,
        });
    };

    const onFocus = async (): Promise<void> => {
        setFieldIsBlurred(false);
        if (!phoneChangeHandler) {
            await initPhoneNumberChangeHandler();
        }
    };
    const onCountryFlagWrapperFocus = async (): Promise<void> => {
        if (!phoneChangeHandler) {
            await initPhoneNumberChangeHandler();
        }
    };
    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
        // Since value could have symbols inside('(', '-', '') or spaces)
        // It needs to be handled manually.
        const value = e.currentTarget.value;
        const selectionStart = e.currentTarget.selectionStart || 0;
        const selectionEnd = e.currentTarget.selectionEnd || 0;
        if (e.key === 'Backspace') {
            const deleted = handleTextDeletion({
                value,
                selectionStart,
                selectionEnd,
                notFormattedNumber,
            });
            setDeletedNumber(deleted);
        } else {
            setDeletedNumber('');
        }
    };
    const flagWrapperPositionClass = classnames({
        [styles.flagWrapperTop]: flagWrapperPosition === 'top',
        [styles.flagWrapperBottom]: flagWrapperPosition === 'bottom',
        [styles.flagWrapperTopWithLabel]: flagWrapperPosition === 'top-label',
    });
    const fieldSize = size || 'large';
    const flagWrapperHeightClass = classnames({
        [styles.flagWrapperHeightSmall]: fieldSize === 'small',
        [styles.flagWrapperHeightMedium]: fieldSize === 'medium',
        [styles.flagWrapperHeightHigh]: fieldSize === 'large',
    });
    return (
        <div className={classnames(styles.phoneNumberWrapper, wrapperClass)}>
            <div
                className={classnames(
                    styles.flagWrapper,
                    flagWrapperHeightClass,
                    flagWrapperPositionClass
                )}
            >
                {isLoading ? (
                    <Spinner containerClass={styles.spinner} />
                ) : (
                    <>
                        <Link
                            dataTn="phoneNumber-flag-container"
                            className={styles.flagIconWrapper}
                            onClick={() => setMenuIsOpen(disabled ? false : !menuIsOpen)}
                        >
                            <img
                                data-tn={`flag-${selectedCountry}`}
                                alt={`${selectedCountry} flag`}
                                src={`https://a.1stdibscdn.com/dist/adhoc/country-flag-images/512x341/${selectedCountry}.png?width=${FLAG_IMAGE_SIZE}`}
                                className={styles.flag}
                            />
                        </Link>
                        <div className={styles.arrow}>
                            <RotatingArrow direction={menuIsOpen ? 'up' : 'down'} />
                        </div>
                        <div className={styles.selectWrapper}>
                            <BasicSelect
                                dataTn="phoneNumber-country-select"
                                onChange={onCountryCodeChange}
                                options={countriesOptions}
                                value={selectedCountry}
                                disabled={disabled}
                                onFocus={onCountryFlagWrapperFocus}
                            />
                        </div>
                    </>
                )}
            </div>

            <Input
                inputRef={input => {
                    inputNodeRef.current = input;
                }}
                className={styles.inputClass}
                errorDataTn={errorDataTn}
                hasAnimatedPlaceholder
                type={type}
                dataTn={dataTn}
                name={name}
                size={size}
                placeholder={placeholder}
                errorMessage={errorMessage}
                hasError={hasError}
                autoFocus={autoFocus}
                disabled={disabled}
                hasValidText={hasValidText}
                maskForPrivacy
                onChange={phoneNumberChange}
                onBlur={phoneNumberBlur}
                value={formattedNumber || ''}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                label={label}
                //Need to pass empty element so that leftDecorator css class would be applied
                leftDecorator={<></>}
                leftDecoratorClass={styles.emptySpacing}
                errorMessageAlignment={errorMessageAlignment}
            />
        </div>
    );
};
